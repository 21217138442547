import React from "react"

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"
import GoogleImage from "../../components/GoogleImage"
import PDFDownloadBox from "../../components/PDFDownloadBox"

const InstallationDmPage = () => {
	return (
		<SupportPage page="installation" title="Tape Method Installation Instructions">
			<PageHeader title="Tape Method Installation Instructions" />

            <PDFDownloadBox
				title="Download Installation Instructions"
                pdfUrl="documents/SignMonkey-Installation-Tape-Method.pdf"
				imageUrl="SignTypes/Install-Guide-thumb.jpg"
            />

			<h3>Step 1 <small>Apply Adhesive Pattern</small></h3>

			<p>Tape the top of the pattern to the wall and level using the horizontal line on the front of the pattern.</p>
			<p className="help-block">
				Do not level with the bottoms of the letters or edge of paper, use provided level lines only.
			</p>
			<div>
				<GoogleImage src="Documents/installation-tape-image8.png" required lazy alt="Apply Adhesive Pattern" className="full" />
			</div>

			<h3>Step 2 <small>Cut Pattern</small></h3>

			<p>
				Cut the pattern every foot or so between the letters to have smaller pieces to lay down.				
			</p>
			<div>
				<GoogleImage src="Documents/installation-tape-image4.png" required lazy alt="Cut Pattern" className="full" />
			</div>

			<h3>Step 3 <small>Peel Pattern</small></h3>

			<p>
				Lift the first cut of the pattern from the bottom and slowly peel down starting at the top where the tape
				is, as you peel, gently and firmly press the pattern to the wall.
			</p>
			<p className="help-block">
				Repeat this process with each section until the entire pattern is firmly pressed to the mounting surface. Bubbles are not a problem
			</p>			
			<div>
				<GoogleImage src="Documents/installation-tape-image1.png" required lazy alt="Peel Pattern" className="full" />
			</div>

			<h3>Step 4 <small>Press To Wall</small></h3>

			<p>
				Once the pattern is on the wall, lift from the bottom corner of each cut line, and peel the top film layer of
				the pattern, leaving the bottom layer stuck to the wall.				
			</p>
			<div>
				<GoogleImage src="Documents/installation-tape-image7.png" required lazy alt="Press To Wall" className="full" />
			</div>

			<h3>Step 5 <small>Peel Film</small></h3>

			<p>
				Peel the film off of the tape on the back of each letter. One at a time				
			</p>
			<div>
				<GoogleImage src="Documents/installation-tape-image2.png" required lazy alt="Peel Film" className="full" />
			</div>

			<h3>Step 6 <small>Apply Letter</small></h3>

			<p>
				Gently place the letter in the correct slot on the wall. Press firmly to ensure the tape is stuck. Tape is
				permanent, once it is stuck it is not possible to adjust.				
			</p>
			<div>
				<GoogleImage src="Documents/installation-tape-image6.png" required lazy alt="Apply Letter" className="full" />
			</div>

			<h3>Step 7 <small>Remove Pattern</small></h3>

			<p>
				In the middle of the pattern, there will be a cut line running horizontally, this makes peeling the
				pattern from the wall easier. Start by peeling the bottom part of the pattern, and working your way to
				the end.			
			</p>
			<div>
				<GoogleImage src="Documents/installation-tape-image5.png" required lazy alt="Remove Pattern" className="full" />
				<GoogleImage src="Documents/installation-tape-image3.png" required lazy alt="Remove Pattern" className="full" />
			</div>

		</SupportPage>
	)
}

export default InstallationDmPage